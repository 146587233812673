import React from 'react';
import './About.css'; // Import the CSS file for styling

const About = () => {
  return (
    <div className="about-container">
      <h2 className="about-title">About Us</h2>
      <div className="about-content">
        <p>
          Welcome to Kings Construction & Remodeling, your trusted partner for quality construction and remodeling services.
        </p>
        <p>
          With over 10 years of experience in the industry, we are a leading construction company specializing in residential and commercial projects. Our mission is to bring your vision to life and deliver exceptional results that exceed your expectations.
        </p>
        <p>
          At Kings Construction & Remodeling, we pride ourselves on our commitment to craftsmanship and attention to detail. We understand that your space is a reflection of your lifestyle and personality, and we work closely with you to ensure that every aspect of your project aligns with your vision. From concept to completion, our dedicated team of skilled professionals will guide you through the process, providing expertise, guidance, and personalized solutions.
        </p>
        <p>
          Our comprehensive range of services includes new construction, home remodeling, kitchen renovation, bathroom remodeling, commercial construction, and office renovation. Whatever your project entails, we have the expertise and resources to turn your dreams into reality.
        </p>
        <p>
          Customer satisfaction is our top priority. We believe in building strong relationships with our clients based on trust, transparency, and open communication. We strive to deliver projects on time, within budget, and with the highest level of quality. Your satisfaction is our ultimate goal.
        </p>
        <p>
          Contact our team today to discuss your construction or remodeling needs. We look forward to the opportunity to work with you and create a space that truly reflects your style and enhances your quality of life.
        </p>
      </div>
    </div>
  );
};

export default About;
