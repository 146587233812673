import React from 'react';
// import './App.css';
import Home from './pages/Home';

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        {/* Replace the existing content with the home component */}
        <Home />
      </header>
    </div>
  );
};

export default App;
